<template>
    <div class="flex">
        <Switch
            :id="name"
            v-model="model"
            :disabled="disabled"
            :name="name"
            class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 dark:ring-offset-gray-800"
            :class="[model ? 'bg-primary-500' : 'bg-gray-200 dark:bg-gray-800 ' + bgColor, disabled ? 'contrast-50 !cursor-not-allowed' : 'contrast-100']">
            <span class="sr-only">Use setting</span>
            <span
                :class="[model ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                <span
                    :class="[model ? 'opacity-0 duration-100 ease-out' : 'opacity-100 duration-200 ease-in', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']"
                    aria-hidden="true">
                    <Icon
                        v-if="icons"
                        :name="icons[0]"
                        class="h-4 w-4 text-gray-600" />
                </span>
                <span
                    :class="[model ? 'opacity-100 duration-200 ease-in' : 'opacity-0 duration-100 ease-out', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']"
                    aria-hidden="true">
                    <Icon
                        v-if="icons"
                        :name="icons[1]"
                        class="h-4 w-4 "
                        :class="model ? 'text-primary-500' : 'text-neutral-200 dark:text-gray-800'" />
                </span>
            </span>
        </Switch>
        <label
            v-if="label"
            :for="name"
            class="ml-2 text-gray-700 dark:text-gray-200 text-sm leading-6">
            {{ label }}
        </label>
    </div>
</template>

<script setup lang="ts">
const emit = defineEmits(['update:modelValue']);
const props = defineProps<{
    modelValue: boolean,
    name: string,
    disabled?: boolean,
    icons?: [string, string],
    label?: string,
    bgColor?: string,
}>();

const model = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    }
})

</script>